import emporaki from '../../assets/img/home1/emporaki.webp';
import emporas from '../../assets/img/home1/Emporas.webp';
import tabImg1 from '../../assets/img/home1/emporas-st-45.webp';
import double from '../../assets/img/home1/doublewire.webp';
import single from '../../assets/img/home1/singlewire.webp';
import cutter from '../../assets/img/home1/cutter.png';
import { Link } from 'react-router-dom';
// import { useState } from 'react';
import 'react-modal-video/scss/modal-video.scss';


const AboutOne = ({pt}) => {
    // const [isOpen, setOpen] = useState(false);

    return (
        <>
        <section className={pt ? 'about-section section-padding' : 'about-section section-padding pt-0'}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-5 col-12">
                        <div className="img-block-pop-video">
                            <img src={emporas} alt="emporas"/>
                            <div className="popup-video-block d-flex justify-content-center align-items-center bg-cover">
                            <img src={emporaki} alt="emporas"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-10 col-12">
                        <div className="block-contents">
                            <div className="section-title">
                                <span>02. Σχετικά με τον Emporas</span>
                                <h2>Η Ιστορία μας</h2>
                            </div>
                            <p>Ο έμπορας ιδρύθηκε το 2023 με σκοπό την εισαγωγή ,πώληση εργαλείων και υλικών οικοδομής. Οι ρίζες για το εμπόριο όμως καθώς και η επιλογή του ονόματος εχούν ξεκινήσει αρκέτα χρόνια πρίν από τον παππού του Ιώαννη Κουτελιέρη , γνωστό στην Νάξο ως Έμπορα(Γιάννης Ανεβλαβής).</p>
                        </div>
                        <div className="tab-content-block">
                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-technology-tab" data-bs-toggle="pill" data-bs-target="#pills-technology" type="button" role="tab" aria-controls="pills-technology" aria-selected="true">Δετικα</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-materials-tab" data-bs-toggle="pill" data-bs-target="#pills-materials" type="button" role="tab" aria-controls="pills-materials" aria-selected="false">Συρματα</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-approved-tab" data-bs-toggle="pill" data-bs-target="#pills-approved" type="button" role="tab" aria-controls="pills-approved" aria-selected="false">Ηλεκτρικοί Κουρμπαδοροι & ψαλιδια</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-technology" role="tabpanel" aria-labelledby="pills-technology-tab">
                                    <div className="tab-inner-contents">
                                        <div className="img-box">
                                            <img src={tabImg1} alt=""/>
                                        </div>
                                        <div className="checked-features-list">
                                            <ul>
                                                <li>Γρήγορα</li>
                                                <li>Αξιόπιστα </li>
                                                <li>Καινοτόμα</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-materials" role="tabpanel" aria-labelledby="pills-materials-tab">
                                    <div className="tab-inner-contents">
                                        <div className="img-box">
                                        <img src={double} alt=""/>
                                        <img src={single} alt=""/>
                                        </div>
                                        <div className="checked-features-list">
                                            <ul>
                                                <li>Γαλβανιζέ σύρματα 0.8mm & 1mm</li>
                                                <li>Συμβάτα με δετικά Emporas , max , makita</li>
                                                <li>Μέγαλο απόθεμα για να μην ξεμείνετε ποτέ</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-approved" role="tabpanel" aria-labelledby="pills-approved-tab">
                                    <div className="tab-inner-contents">
                                        <div className="img-box">
                                        <img src={cutter} alt=""/>
                                        </div>
                                        <div className="checked-features-list">
                                            <ul>
                                                <li>Γρήγορο κόψιμο</li>
                                                <li>Με ρεύμα ή μπαταρία</li>
                                                <li>Για κάθε περίσταστη</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>                          
                        </div>
                        <Link to="/about" className="plus-text-btn mt-lg-5 mt-4">
                            <div className="icon">
                                <i className="fas fa-plus"></i>
                            </div>
                            <div className="link-text">
                                <span>Ανακαλύψτε</span> Περισσότερα για εμάς
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default AboutOne;