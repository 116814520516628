import React from 'react'
import icon1 from '../../assets/img/home1/BZ450.webp';
import icon3 from '../../assets/img/home1/emporas-st-45.webp';
import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <section className="pricing-wrapper section-padding pricing-bg">
        <div className="container">
        <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
            <div className="block-contents text-center">
                <div className="section-title">
                <span>Χαρακτηριστικα</span>
                <h2>Ανακαλυψτε τι σας ταιριαζει</h2>
                </div>
            </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
            <div className="single-pricing-plan">
                <div className="package-head">
                <div className="icon">
                    <img src={icon1} alt="" />
                </div>
                <div className="package-title">
                    <h6>ST-545</h6>
                    <h2>Οικονομικο μοντελο</h2>
                </div>
                </div>
                <div className="package-features">
                <span>Μέγιστη διάμετρο: 40Φ</span>
                <span>Ταχύτητα: 0.75 δευτ</span>
                <span>Δεσίματα: 120-150</span>
                <span>2 Μπαταρίες 14v 4ah</span>
                <span>Κατανάλωση σύρματος: 68-70εκ</span>
                </div>
                <div className="package-btn">
                <Link to="/contact">Ζητηστε προσφορα</Link>
                </div>
            </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <div className="single-pricing-plan active">
                <div className="package-head">
                <div className="icon">
                    <img src={icon3} alt="" />
                </div>
                <div className="package-title">
                    <h6>ST-45</h6>
                    <h2>Οικονομια συρματος</h2>
                </div>
                </div>
                <div className="package-features">
                <span>Μέγιστη διάμετρο: 46Φ</span>
                <span>Ταχύτητα: 0.6 δευτ</span>
                <span>Δεσίματα: 200-260</span>
                <span>2 Μπαταρίες 18v 5ah</span>
                <span>Κατανάλωση σύρματος: 10-15εκ</span>
                </div>
                <div className="package-btn">
                <Link to="/contact">Ζητηστε προσφορα</Link>
                </div>
            </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <div className="single-pricing-plan">
                <div className="package-head">
                <div className="icon">
                    <img src={icon3} alt="" />
                </div>
                <div className="package-title">
                    <h6>ST-60</h6>
                    <h2>Εξειδικευμενες κατασκευες</h2>
                </div>
                </div>
                <div className="package-features">
                <span>Μέγιστη διάμετρο: 66Φ</span>
                <span>Ταχύτητα: 0.6 δευτ</span>
                <span>Δεσίματα: 200-260</span>
                <span>2 Μπαταρίες 18v 5ah</span>
                <span>Κατανάλωση σύρματος: 10-15εκ</span>
                </div>
                <div className="package-btn">
                <Link to="/contact">Ζητηστε προσφορα</Link>
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>  

  )
}

export default Pricing