import { isValidElement } from 'react';
import {v4 as uuidv4} from 'uuid';

const faqOneData = [
    {
        id: uuidv4(),
        ques: 'Τι είναι τα δετικά εργαλεία μπετοσιδήρου;',
        ans: 'Τα δετικά εργαλεία μπετοσιδήρου είναι ειδικά σχεδιασμένα εργαλεία που χρησιμοποιούνται για να δένουν μεταξύ τους τα σιδερένια ράβδοι (οπλισμός) που χρησιμοποιούνται για την ενίσχυση του μπετόν σε κατασκευές.',
        index: 'faq1',
    },

    {
        id: uuidv4(),
        ques: 'Γιατί τα δετικά εργαλεία είναι απαραίτητα για τον επαγγέλματια σιδέρα και εργολάβο;',
        ans: `Τα δετικά εργαλεία είναι απαραίτητα για μια σειρά λόγων, με τους πιο σημαντικούς να είναι οι εξής: 
        Πρώτον, τα δετικά εργαλεία συμβάλλουν στην εξοικονόμηση χρόνου και χρημάτων, καθώς μπορούν να είναι έως και πέντε φορές ταχύτερα από τις παραδοσιακές μεθόδους δεσίματος.
        Δεύτερον, τα δετικά εργαλεία προσφέρουν άνεση κατά τη χρήση, καθώς δεν απαιτούν σημαντική κατανάλωση ενέργειας για να δέσουν τους δοκούς, μειώνοντας την κόπωση των χρηστών.
        Τρίτον, τα δετικά εργαλεία είναι προσιτά σε όλους, καθώς δεν απαιτούν εξειδικευμένες τεχνικές γνώσεις για τη χρήση τους. Κατα αυτόν τον τρόπο, οποιοσδήποτε μπορεί να τα χρησιμοποιήσει με ασφάλεια και αποτελεσματικότητα.`,
        index: 'faq2'
    },

    {
        id: uuidv4(),
        ques: 'Ποια είδη δετικών εργαλείων μπετοσιδήρου υπάρχουν;',
        ans: 'Υπάρχουν διάφορα είδη δετικών εργαλείων μπετοσιδήρου. Τα πιο συνηθισμένα είναι τα δετικά εργαλεία τύπου ST-545 και τύπου ST-45. Το πρώτο εργαλείο είναι πιο οικονομικό, αλλά καταναλώνει περισσότερο σύρμα, ενώ το δεύτερο(ST-45) εργαλείο είναι πιο γρήγορο, εύκολο στη χρήση και κάνει οικονομία στό σύρμα.',
        index: 'faq3'
    },

    {
        id: uuidv4(),
        ques: 'Τι περιέχει η συσκευασία',
        ans: 'Η συσκεύασιες των δετικών μας περιλαμβάνουν 2 μπαταρίες και 2 ή 3 μπομπίνες σύρματος ανάλογα το δετικό εργαλείο.',
        index: 'faq4'
    },

    // {
    //     id: uuidv4(),
    //     ques: 'Can you work with clients long distance?',
    //     ans: 'We have intentionally never developed a stylistic formula for our work, which is why our projects never quite look or feel the same. Each of our spaces is the result of our ability to listen.',
    //     index: 'faq5'
    // },

]

export default faqOneData;