import React from 'react'
import { Link } from 'react-router-dom'

const MobileMenu = ({mobileMenu, handleMobileMenu}) => {
  return (
    <div className={mobileMenu ? 'mobile-nav' : 'mobile-nav show'}>
        <button onClick={handleMobileMenu} type="button" className="close-nav">
            <i className="fal fa-times-circle"></i>
        </button>
        <nav className="sidebar-nav">
            <ul className="metismenu" id="mobile-menu">
            <li><Link to="#">Αρχική</Link></li>
                {/* <li><a className="has-arrow" href="#">Homes</a>
                    <ul className="sub-menu">
                        <li><Link to="/">homepage 1</Link></li>
                        <li><Link to="/homeTwo">homepage 2</Link></li>
                        <li><Link to="/homeThree">homepage 3</Link></li>
                        <li><Link to="/homeFour">homepage 4</Link></li>
                    </ul>
                </li> */}
                <li><Link to="/about">Σχετικά Με Εμάς</Link></li>
                <li><Link to="/services">Προϊόντα</Link></li>
                <li>
                    <a className="has-arrow" href="#">Σελίδες</a>
                    <ul className="sub-menu">
                        <li><Link to="/faq">Ερωτήσεις</Link></li>
                        <li><Link to="/pricing">Χαρακτηριστικα</Link></li>
                        {/* <li><Link to="/team">Team</Link></li> */}
                    </ul>
                </li>
                {/* <li><Link to="/news">News</Link></li> */}
                <li><Link to="/contact">Επικοινωνήστε</Link></li>
            </ul>
        </nav>

        <div className="action-bar">
            <a href="mailto:sales@emporas.gr"><i className="fal fa-envelope"></i>sales@emporas.gr</a>
            <a href="tel:306970497404"><i className="fal fa-phone"></i>+306970497404</a>
            <Link to='/contact' className="d-btn theme-btn">Επικοινωνηστε Μαζι μας</Link>
        </div>
    </div>    
     
  )
}

export default MobileMenu