import { Link } from "react-router-dom";

const ContactPageContents = () => {

    return (
        <div className="contact-us-wrapper section-padding">
            <div className="container">
                <div className="row eq-height">
                    <div className="col-lg-8 col-12">
                        <div className="contact-form"> 
                            <h2>Επικοινωνηστε μαζι μας</h2>                                                       
                            <form 
                                action="https://getform.io/f/byvvvlqa" 
                                method="POST" 
                                className="row" 
                                id="contact-form"
                            >
                                {/* No hidden input is required with Getform */}
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <input type="text" name="name" placeholder="Όνομα" required />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <input type="email" name="email" placeholder="Email" required />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <input type="text" name="phone" placeholder="Κινητό" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <input type="text" name="subject" placeholder="Θέμα" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="single-personal-info">
                                        <textarea name="message" placeholder="Μήνυμα" defaultValue={""} />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <input className="submit-btn" type="submit" value="Submit Now" />
                                </div>
                            </form>
                            <span className="form-message" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                        <div className="contact-us-sidebar mt-5 mt-lg-0">
                            <div className="contact-info">
                                <h2>Πληροφοριες επικοινωνιας</h2>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-email" />
                                    </div>
                                    <div className="text">
                                        <span>Email Us</span>
                                        <h5>Sales@emporas.gr</h5>
                                    </div>
                                </div>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-phone-call-1" />
                                    </div>
                                    <div className="text">
                                        <span>Καλέστε μας</span>
                                        <h5>+30 6970497404</h5>
                                    </div>
                                </div>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-pin" />
                                    </div>
                                    <div className="text">
                                        <span>Τοποθεσία</span>
                                        <h5>Μέλανες 0 , Νάξος, Κυκλάδες</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactPageContents;
