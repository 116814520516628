const HeroSocials = () => {
    return (
        <>
        <div className="hero-social-elements d-none d-xxl-block">
        <div className="flp-text">
            <p>Ακολουθηστε μας</p>
        </div>
        <div className="long-arrow"></div>
        <div className="social-link">
            <a href="https://www.facebook.com/people/Emporas/100089037050724/"><i className="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/emporas_gr/"><i className="fab fa-instagram"></i></a>
            <a href="https://www.youtube.com/@emporas_gr"><i className="fab fa-youtube"></i></a>
        </div>
    </div>
        </>
    )
}

export default HeroSocials