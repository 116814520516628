import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/home1/slide1.jpg';
import PageBanner from '../Components/PageBanner';
// import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import ContactPageContents from "../Components/Contact/ContactPageContents";

const ContactPage = () => {
  return (
    <>
        <HeaderOne/>
        <PageBanner title='Επικοινωνηστε μαζι μας' bannerBg={bannerBg} currentPage='Επικοινωνηστε' />
        <ContactPageContents/>
        {/* <BrandCarouselThree/> */}
        <FooterTwo/>
    </>
  )
}

export default ContactPage;
