import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/client/1.jpg';

const TestimonialTwoData = [
    {
        id: uuidv4(),
        name: 'Θοδωρης Καποθτσος',
        designation: 'Εργολάβος',
        speech: 'Αυτό είναι το μέλλον.',
    },

    {
        id: uuidv4(),
        name: 'Flaton Elezaj',
        designation: 'Σιδεράς',
        speech: 'Σε κάθε επάγγελμα ,τα εργαλεία βγάζουν δουλειά. Το συγκεκριμένο εργαλείο , είναι γρήγορο και ξεκούραστο.',
    },
   
]

export default TestimonialTwoData;