import { Link } from 'react-router-dom';
import mainLogo from '../../assets/img/logo.png';

const OffsetMenu = ({offset, handleOffset}) => {
    return (
        <>
        <div className={offset? 'offset-menu': 'offset-menu show'}>
            <span onClick={handleOffset} id="offset-menu-close-btn"><i className="fal fa-plus"></i></span>
            <div className="offset-menu-wrapper text-white">
                <div className="offset-menu-header">
                    <div className="offset-menu-logo">
                        <Link to="/homeOne"><img src={mainLogo} alt="logo"/></Link>
                    </div>
                </div> 
                <div className="offset-menu-section">
                    <h3>Σχετικά με εμάς</h3>
                    <p>Στόχος μας η ποιότητα.Διπλά στον επαγγέλματια με καινοτόμα εργαλεία και προϊόντα! </p>
                    <Link to="/contact" className="theme-btn  mt-30">Επικοινωνία</Link>
                </div>
                <div className="offset-menu-section">
                    <h3>Πληροφοριες Επικοινωνιας</h3>
                    <ul>
                        <li><span><i className="fal fa-map"></i></span>Μελάνες,Νάξος,Κύκλαδες, Ελλάδα</li>
                        <li><span><i className="fal fa-phone"></i></span>(30) 697-049-7404</li>
                        <li><span><i className="fal fa-envelope"></i></span>sales@emporas.gr</li>
                        <li><span><i className="fal fa-clock"></i></span>Εβδομαδιαίο Πρόγραμμα: 09.00 to 16.00</li>
                        <li><span><i className="fal fa-clock"></i></span>Κυριακή: Κλειστά</li>
                    </ul>
                </div> 
                <div className="offset-menu-footer">
                    <div className="offset-menu-social-icon">
                    <a href="https://www.facebook.com/people/Emporas/100089037050724/"><i className="fab fa-facebook-f"></i></a>
                    <a href="https://www.youtube.com/@emporas_gr"><i className="fab fa-youtube"></i></a>
                    <a href="https://www.instagram.com/emporas_gr/"><i className="fab fa-instagram"></i></a>
                    </div>
                </div>    
            </div>               
        </div>
        </>
    )
}

export default OffsetMenu;