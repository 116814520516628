import {v4 as uuidv4} from 'uuid';
import bg1 from '../../assets/img/home1/emporas-st-45.webp';

const servicesOneData = [
    {
        id: uuidv4(),
        bg: bg1,
        icon: 'blueprint',
        title: 'Οικοδομικα εργαλεια',
        desc: 'Εργαλεία που θα σας λύσουν τα χέρια και θα αυξήσουν την παραγωγικότητα σας!',
    },

    // {
    //     id: uuidv4(),
    //     bg: bg1,
    //     icon: 'interior-design',
    //     title: 'Interior Design',
    //     desc: 'We commit ourselves to complete all projects within the timeline set with our honorable clients.',
    // },

    // {
    //     id: uuidv4(),
    //     bg: bg1,
    //     icon: 'home',
    //     title: 'Home Decoration',
    //     desc: 'We commit ourselves to complete all projects within the timeline set with our honorable clients.',
    // },

    // {
    //     id: uuidv4(),
    //     bg: bg1,
    //     icon: 'varnish',
    //     title: 'Μονωτικά & Σφραγιστηκά',
    //     desc: 'Δοκιμασμένα προϊόντα για κάθε πρόβλημα από την κορύφαια Ελληνική εταίρια στις μονώσεις Elastotet.',
    // },

    // {
    //     id: uuidv4(),
    //     bg: bg1,
    //     icon: 'sketch-1',
    //     title: 'Sketching',
    //     desc: 'We commit ourselves to complete all projects within the timeline set with our honorable clients.',
    // },
    
]

export default servicesOneData;